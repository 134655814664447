import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';

const Paragraph = ({ title, children }) => {
  return (
    <>
      <Title title={title} />
      {children}
    </>
  );
};

Paragraph.propTypes = {
  title: PropTypes.string,
};

export default Paragraph;
