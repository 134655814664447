import React from 'react';
import { graphql, Link } from 'gatsby';
import { Container, Button } from 'react-bootstrap';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import HeroImage from '../components/HeroImage';
import Paragraph from '../components/Paragraph';
import FeaturedProducts from '../components/FeaturedProducts';

export const query = graphql`
  query {
    backgroundImg: file(relativePath: { eq: "default-background.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout pageInfo={{ pageName: 'index' }}>
    <SEO title="Home" />
    <HeroImage img={data.backgroundImg.childImageSharp.fluid}>
      <h1 className="text-white">GatsbyStore</h1>
    </HeroImage>
    <Container>
      <Paragraph title="Welkom bij GatsbyStore">
        <p className="text-center">
          Veggies es bonus vobis, proinde vos postulo essum magis kohlrabi welsh
          onion daikon amaranth tatsoi tomatillo melon azuki bean garlic.
        </p>
      </Paragraph>
      <FeaturedProducts />
      <div className="d-flex justify-content-center mt-2 mb-5">
        <Link to="/producten">
          <Button variant="outline-success">Alle producten &raquo;</Button>
        </Link>
      </div>
    </Container>
  </Layout>
);

export default IndexPage;
